/* ******** BACKGROUND ******* */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* Includes padding in width calculation */
}



/* ******** HOME ******* */
.container {
  font-family: sans-serif;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* min-height: 100vh; Footer sticks to bottom if needed */
  box-sizing: border-box;
  /* Ensures padding doesn’t exceed width */
}



/* ******** NAVBAR ******* */
.nav-bg {
  font-family: poppins;
  padding: 5px;
  height: auto;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: height 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.left {
  margin-left: 60px;
  display: flex;
  align-items: center;
}

.nav-links ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links .nav-item a {
  text-decoration: none;
  margin: 0 15px;
  color: #333;
}

.menu-button {
  display: none;
}

@media (max-width: 768px) {
  .menu-button {
    display: block;
  }

  .nav-links {
    display: none;
  }
}

.nav-links ul li {
  list-style: none;
  display: inline-block;
  padding: 8px 22px;
  position: relative;
}

.nav-links ul li a {
  color: #000000;
  text-decoration: none;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
}

.home-dropdown {
  margin-left: 10px;
  position: relative;
}

.home-toggle-dropdown {
  font-weight: 400;
  cursor: pointer;
}

.home-dropdown-menu {
  display: none;
  position: absolute;
  top: 120%;
  left: 0;
  background-color: #A2CFCD;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px;
  margin: 0;
  z-index: 999;
}

.home-dropdown-menu li {
  padding: 8px 20px;
}

.home-dropdown-menu li a {
  color: #333;
  text-decoration: none;
  display: block;
}

.login-dropdown {
  display: None;
  flex-direction: column;
  /* Stack items vertically */
  position: absolute;
  top: 120%;
  left: 0;
  background-color: #A2CFCD;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px;
  margin: 0;
  z-index: 999;
  width: 120px;
  /* Adjust width if necessary */
}

.login-dropdown li {
  padding: 8px;
  text-align: center;
  width: 100%;
}

.login-dropdown li a {
  display: block;
  text-decoration: none;
  color: #333;
  width: 100%;
  padding: 8px 0;
}

.login-dropdown li:hover {
  background-color: #f5f5f5;
}

/* .home-dropdown-menu li a:hover {
  background-color: #f5f5f5;
} */

.home-dropdown .home-dropdown-menu {
  display: block;
}

.signup-link {
  border: none;
  padding: 8px 15px 8px 15px;
  background-color: #04454A;
  border-radius: 8px;
  color: white;
  margin-left: 20px;
  text-decoration: none;
}

.signup-linkk:hover {
  background-color: #063e42;
}

.nav-login {
  display: none;
  position: absolute;
  left: 80%;
  top: 18%;
}

.user-icon-container {
  display: None;
}

@media (max-width: 768px) {
  .nav-login {
    display: flex;


  }

  .user-icon-container {
    display: flex;
    position: absolute;
    left: 80%;
    top: 18%;
  }

  .login-dropdown {
    display: flex;
    position: absolute;
    top: 120%;
    left: 0;
    background-color: #A2CFCD;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 10px;
    margin: 0;
    z-index: 999;
  }

}

.menu-button {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #000;
  position: absolute;
  left: 90%;
}

.close-button {
  display: flex;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #000;
  position: absolute;
  left: 90%;

}

.navbar-nav {
  display: flex;
  list-style: none;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  background-color: #A2CFCD;
  /* Background color for the menu */
  padding: 20px;
  /* Padding for the menu */
  position: absolute;
  /* Position it absolutely */
  top: 50px;
  /* Adjust based on your navbar height */
  left: 0;
  right: 0;
  z-index: 1000;
  /* Ensure it appears above other content */
  width: 100% !important;
  height: 100vh !important;
  position: fixed;
  border-radius: 0;
}

.mobile-menu ul {
  list-style: none;
  /* Remove bullet points */
  padding: 0;
  /* Remove padding */
  margin: 0;
  /* Remove margin */
}

.mobile-menu li {
  margin: 10px 0;
  /* Space between menu items */
}

.mobile-menu a {
  text-decoration: none;
  /* Remove underline */
  color: #000000;
  /* Link color */
  font-size: 18px;
  /* Font size for links */
}




/* ******** SEARCHBAR ******* */
.hero {
  background-color: #ffff;
  padding: 80px 0px 0px 0px;
  text-align: center;
}

.hero h1 {
  color: #04454A;
  font-family: roboto;
  font-weight: 1000;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero h3 {
  color: #757575;
}

.home-search-container {
  position: relative;
  max-width: 1200px;
  margin: 60px auto;
  cursor: pointer;
}

.search-icon-upper {
  position: absolute;
  top: -30px;
  /* Adjust this value to position the icon above the search bar */
  left: 82%;
  transform: translateX(-50%);

}

.search-bar {
  width: 60%;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  position: relative;
}

.search-bar input {
  flex: 1;
  font-size: 18px;
  padding: 8px 40px;
  border: 2px solid black;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.search-bar .search-icon-left,
.search-bar .search-icon-right {
  position: absolute;
  /* width: 24px;
  height: 24px; */
  pointer-events: none;
  /* Prevent icons from blocking input clicks */
}

.search-bar .search-icon-right {
  width: 50px;
}

.search-bar .search-icon-left {
  left: 12px;
  top: 65%;
  transform: translateY(-50%);
}

.search-bar .search-icon-right {
  right: 6px;
  top: 68%;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .auth-container {
    width: 100%;
    margin: 50px 10px 10px 10px;
  }

  .search-icon-upper {
    left: 50%;
  }
}

.how-it-works {
  padding: 50px 20px;
  text-align: center;
}

.how-it-works h2 {
  color: #04454A;
}

.steps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0rem;
  padding-top: 8px;
  max-width: 1000px;
  margin: 0 auto;
}

.step {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 300px;
}

.step img {
  width: 300px;
  height: 300px;
  object-fit: contain;

}

.step h3 {
  font-weight: 700;
  font-size: 1.2rem;
  color: #333;
}

.step p {
  color: #666;
  line-height: 1;
}

@media (max-width: 768px) {
  .steps {
    flex-direction: column;
    align-items: center;
  }

  .step {
    max-width: 100%;
    margin-bottom: 2rem;
  }
}



/* ******** SERVICES ******* */
.services {
  padding-top: 50px;
  background-color: #a1cfcc;
  text-align: center;
}

.services h1 {
  color: #04454A;
}

.services p {
  font-size: 18px;
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem;
  max-width: 1100px;
  margin: 0 auto;
}

.service-card {
  border-radius: 6px;
  flex: 1 1 calc(30% - 1rem);
  max-width: 300px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-card-content {
  position: relative;
  width: 100%;
}

.service-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
  filter: brightness(0.8);
  /* Make the whole image dull */

}

.service-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  color: white;
  /* Ensure text is always white */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;
}

.service-overlay h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.service-overlay p {
  margin: 0.5rem 0 1rem 0;
  font-size: 14px;
  line-height: 1;
  text-align: left;
}

.read-more {
  background-color: #FF6F61;
  /* Button color */
  color: #fff;
  padding: 8px 16px;
  font-size: 14px;
  width: 95%;
  border: none;
  margin: 0 auto;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto;
}

.read-more:hover {
  background-color: #e65b50;
  /* Darker shade of button color on hover */
}



/* ******** PROVIDERS ******* */
.providers {
  background-color: #a1cfcc;
  padding: 50px 20px;
}

.provider-features {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  align-items: flex-start;
}

.feature-card {
  flex: 1;
  text-align: left;
  padding: 1rem;
  background-color: transparent;
}

.image-wrapper {
  width: 300px;
  height: 300px;
  margin: 0 auto 1rem auto;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature-card p {
  font-size: 18px;
  line-height: 1;
  color: #333;
  max-width: 300px;
  margin: 0 auto;
}

.trusted-by-section {
  background-color: white;
  padding: 20px;
  margin: 30px 0;
  text-align: center;
}

.trusted-by-section p {
  font-weight: 700;
  font-size: 18px;
}

.trusted-logos {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 20px 0;
}

.trusted-logos img {
  height: 50px;
  width: auto;
  object-fit: contain;
  margin: 0 1rem;
}

.mobile-service-cards {
  display: None;
}

.services-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  user-select: none;
  padding-inline: 1rem;
  margin-bottom: 20px;
}

/* Card styling for mobile */
.slider-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Reduced padding for mobile */
  width: 100%;
  /* Ensure it takes full width */
  box-sizing: border-box;
  /* Include padding in width */

}

.slider-card-image {
  width: 100%;
  /* Adjust width for mobile */
  max-width: 100%;
  /* Set a maximum width for images */
  height: auto;
  /* Maintain aspect ratio */
  border-radius: 10px;
}

.slider-card-content {
  margin-top: 10px;
  /* Reduced margin for mobile */
}

.slider-card-content h3 {
  font-size: 3vw;
  /* Adjust font size for mobile */
  margin-bottom: 2vw;
  /* Reduced margin for mobile */
}

.slider-card-content p {
  font-size: 2vw;
  /* Adjust font size for mobile */
  color: #555;
}

@media (max-width: 768px) {

  .provider-features {
    flex-direction: column;
    align-items: center;
  }

  .mobile-service-cards {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    /* Hide overflow for swipe effect */
    padding: 20px;


  }

  .service-cards {
    display: None;
  }

  .service-card,
  .feature-card {
    max-width: 100%;
    margin-bottom: 2rem;
  }
}



/* ******** FOOTER ******* */
.footer {
  background-color: #037575;
  /* Match the teal background color */
  padding: 20px 0;
  color: #fff;
  font-family: Arial, sans-serif;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 0 20px; */
}

.footer-logo {
  max-width: 1200px;
  margin: 0 auto;
}

.footer .footer-logo img {
  width: 180px;
}

.footer-links {
  margin-top: 40px;
  display: flex;
  gap: 30px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #a6f1f3;
}

.footer-socials {
  display: flex;
  gap: 15px;
}

.footer-socials a img {
  width: 30px;
  height: 30px;
  /* filter: invert(100%);   */
  transition: filter 0.3s ease;
}

.copyright-text {
  text-align: center;
  background-color: white;
  margin-top: 10px;
  color: #757575;
}

.footer-socials a img:hover {
  filter: invert(75%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(95%);
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }

  .nav-links {
    display: none;
  }

  .nav-links.show {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem;
  }

  .menu-button {
    display: block;
  }

  .service-cards {
    grid-template-columns: 1fr;
  }
}



/* ******** SIGNUP DOOR ******* */
.signup-door {
  position: fixed;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.signup-door h2 {
  color: #000;
  margin-bottom: 20px;
  font-size: 24px;
}

.signup-door .signup-door-options {
  display: flex;
  gap: 20px;
}

.signup-door-option {
  background-color: #037575;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;
}



/* ******** HOME SIDEBAR ******* */
.home-sidebar {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100vh;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  transition: left 0.3s ease;
  z-index: 100;
}

.home-sidebar-header {
  display: flex;
  justify-content: space-between;
}

.home-sidebar-header img {
  height: 40px;
}

.home-sidebar-header h2 {
  margin-top: 4px;
}

.home-sidebar-open {
  left: 0;
}

.close-btn {
  font-size: 25px;
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  margin-bottom: 20px;
}

.home-sidebar-nav {
  font-size: 18px;
  list-style: none;
  padding: 0;
}

.home-sidebar-nav .nav-item {
  margin: 10px 0;
}

@media screen and (max-width: 992px) {
  .nav-bg {
    position: relative;
    top: 0;
    z-index: 0;
    padding: 0;
  }



  .nav-links {
    display: none;
  }

  .nav-links.show {
    display: flex;
    flex-direction: column;
  }

  .contact-link {
    padding: 6px;
  }
}



/* ********* MODAL OVERLAY *********** */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #ececec;
  padding: 20px;
  max-width: 500px;
  max-height: 90vh;
  overflow-x: visible;
  overflow-y: auto;
  scroll-behavior: smooth;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.modal-actions {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

.confirm-button {
  background-color: rgb(7, 118, 7);
  color: white;
  border: none;
  padding: 8px 14px;
  border-radius: 10px;
  cursor: pointer;
}

.cancel-button {
  background-color: rgb(171, 35, 35);
  color: white;
  border: none;
  padding: 8px 14px;
  border-radius: 10px;
  cursor: pointer;
}

.modal-content::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.modal-content::-webkit-scrollbar-thumb {
  border-radius: .5rem;
  background-color: #0004;
}

.terms-heading {
  display: flex;
  justify-content: space-between;
}



/* ********AUTH CONTAINER********** */
.auth-container {
  background-color: #ffffff;
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  text-align: left;
  border-radius: 10px;
  font-family: Arial, sans-serif;
}

.auth-container h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.auth-container p {
  font-size: 1rem;
  color: #6c757d;
}

.auth-container .form-group {
  margin-bottom: 15px;
  text-align: left;
}

.auth-container label {
  display: block;
  font-size: 1rem;
  color: #333;
}

.auth-container input,
select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
  box-sizing: border-box;
}

.auth-container input:focus {
  border-color: #80bdff;
  outline: none;
}

.auth-container .sign-in-button {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.auth-container .sign-in-button:hover {
  background-color: #0056b3;
}

.auth-container a {
  color: #007bff;
  text-decoration: none;
}

.auth-container a:hover {
  text-decoration: underline;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;
}

.checkbox-group input[type="checkbox"] {
  width: auto;
}

.password-errors {
  color: red;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 0px;
  list-style: none;
  padding: 0;
}

.password-errors li {
  margin-bottom: 2px;
}


.insurance-options {
  display: flex;
  gap: 40px;
}

.insurance-options label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.insurance-note {
  margin-top: 18px;
}

@media (max-width: 768px) {
  .auth-container {
    width: 100%;
    margin: 50px 10px 10px 10px;
  }
}


/* ********REQUEST POPUP MODAL********** */
.clinic-request-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  width: 400px;
  border-radius: 10px;
  border: 1px solid black;
  position: relative;
  text-align: center;
}

.close-popup-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.close-popup-button:hover {
  color: red;
}

.popup-content .form-group {
  margin-bottom: 15px;
  text-align: left;
}

.popup-content .form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.popup-content .form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-request-button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-request-button:hover {
  background-color: #0056b3;
}

.request-clinic-button {
  border: none;
  background-color: white;
  margin-top: 5px;
  color: rgb(223, 135, 135);
  text-align: left;
}

.success-message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



/* ********LIVE CHATBOT********** */
.livechat-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.topic-button {
  background-color: #007bff;
  /* Button background color */
  color: #1A8980;
  /* Text color */
  border: 2px solid black;
  border-radius: 20px;
  /* Curvy corners */
  padding: 10px 20px;
  /* Padding for the button */
  margin: 5px;
  /* Space between buttons */
  cursor: pointer;
  /* Pointer cursor on hover */
  transition: background-color 0.3s border-color 0.3s;
  /* Smooth transition for hover effect */
}

.topic-button:hover {
  background-color: #0056b3;
  /* Darker shade on hover */
  border-color: #000;
}

.chat-window {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 480px;
  height: 600px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.resizer {
  width: 10px;
  height: 10px;
  background: #ccc;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: nwse-resize;
}

.chat-header {
  padding: 1rem;
  background: #f3f4f6;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.agent-info {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.agent-avatar {
  width: 2rem;
  height: 2rem;
  background: #d1d5db;
  border-radius: 50%;
}

.agent-name {
  font-weight: 600;
}

.agent-title {
  font-size: 0.875rem;
  color: #6b7280;
}

.close-button {
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
}

.chat-messages {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
}

.message {
  margin-bottom: 1rem;
}

.message.user {
  text-align: right;
}

.message-bubble {
  display: inline-block;
  padding: 0.5rem;
  border-radius: 0.5rem;
  max-width: 80%;
}

.message.user .message-bubble {
  background: #1A8980;
  color: white;
}

.message.bot .message-bubble {
  background: white;
  color: black;
}

.topic-button {
  padding: 0.5rem;
  text-align: left;
  border: none;
  border-radius: 0.25rem;
  background: #f9fafb;
  margin-bottom: 0.5rem;
  width: 100%;
  cursor: pointer;
}

.topic-button:hover {
  background: #f3f4f6;
}

.chat-input {
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
}

.input-form {
  display: flex;
  gap: 0.5rem;
}

.message-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
}

.send-button {
  padding: 0.5rem;
  background: #757575;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.chat-toggle {
  background: #14b8a6;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}


.chat-image img {
  width: 50%;
  /* Adjust the width as needed */
  max-width: 40px;
  /* Set a maximum width */

}






/* Mobile Navigation Updates */
@media (max-width: 768px) {
  .navbar {
    padding: 10px;
    flex-wrap: wrap;
  }

  .left {
    margin-left: 10px;
    width: 100%;
    justify-content: space-between;
  }

  .logo-img {
    max-width: 120px;
  }

  .right {
    display: none;
  }

  .nav-links {
    display: none;
  }

  .nav-links.show {
    display: block;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-links ul {
    flex-direction: column;
  }

  .nav-links ul li {
    padding: 10px 0;
  }

  /* Hero Section Mobile Updates */
  .hero {
    padding: 40px 20px;
  }

  .hero h1 {
    font-size: 1.8rem;
    padding: 0 10px;
  }

  .hero h3 {
    font-size: 1rem;
    padding: 0 10px;
  }

  .search-bar {
    width: 90%;
  }

  .search-bar input {
    font-size: 14px;
  }

  /* How It Works Section Mobile Updates */
  .steps {
    flex-direction: column;
    padding: 20px;
  }

  .step {
    margin-bottom: 30px;
    align-items: center;
    text-align: center;
  }

  .step img {
    width: 200px;
    height: 200px;
  }

  /* Services Section Mobile Updates */
  .services {
    padding: 30px 10px;
  }

  .service-cards {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .service-card {
    width: 100%;
    margin-bottom: 20px;
  }

  /* Providers Section Mobile Updates */
  .provider-features {
    flex-direction: column;
  }

  .feature-card {
    margin-bottom: 30px;
  }

  .image-wrapper {
    width: 200px;
    height: 200px;
  }

  /* Footer Mobile Updates */
  .footer-bottom {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .footer-links {
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }

  .footer-socials {
    justify-content: center;
  }

  /* Live Chat Mobile Updates */
  .chat-window {
    width: 100% !important;
    height: 100vh !important;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0;
  }

  .resizer {
    display: none;
  }
}

/* General Mobile Improvements */
@media (max-width: 480px) {
  .auth-container {
    padding: 15px;
  }

  .insurance-options {
    flex-direction: column;
    gap: 10px;
  }

  .about-us-team-member {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .about-us-team-member-details {
    margin-top: 20px;
  }
}







/* ********ABOUT US********** */
.about-us-container {
  max-width: 1200px;
  margin: 0 auto;
}

.about-us-section {
  margin-top: 50px;
  text-align: center;
}

.about-us-section h1 {
  font-weight: bold;
  margin-bottom: 10px;
  color: #004d40;
}

.about-us-tagline {
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
  color: #757575;
  text-align: center;
}

.about-us-mission-vision {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 100px;
}

.about-us-mission,
.about-us-vision {
  background-color: #ffffff;
  padding: 15px;
  line-height: 1.2;
  font-weight: 500;
}

.about-us-mission-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-us-mission h2,
.about-us-vision h2 {
  color: #757575;
  font-size: 18px;
  margin-bottom: 5px;
}

.about-us-team-section {
  margin-bottom: 20px;
}

.about-us-team-section h1 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #004d40;
}

/* Team Member */
.about-us-team-member {
  display: flex;
  gap: 15px;
  align-items: flex-start;
  background-color: #ffffff;
  margin-bottom: 100px;
}

.about-us-team-member-photo {
  padding-top: 30px;
}

.about-us-team-member-photo img {
  width: 80%;
  height: auto;
  object-fit: cover;
}

.about-us-team-member-details {
  flex: 1;
}

.about-us-team-member-details h3 {
  margin-bottom: 5px;
  font-weight: 500;
  color: #000000;
  text-align: left;
}

.about-us-role {
  font-size: 14px;
  color: #555;
  margin-bottom: 15px;
  font-weight: bold;
}

.about-us-team-member-details p {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin-bottom: 10px;
  text-align: justify;
  font-size: 14px;
}

/* Team Section Layout */
.team-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 120px;
}

.title {
  text-align: center;
  background-color: #056d73;
  color: #ffffff;
  padding: 10px 0;
}

.marketing-title {
  grid-column: span 1;
  /* Spans one image space */
}

.ux-title {
  grid-column: span 2;
  /* Spans two image spaces */
}

.advisors-title {
  grid-column: span 2;
  /* Spans two image spaces */
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.marketing {
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ux-engineering {
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.advisors {
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.member {
  text-align: left;
  background-color: white;
  border: 1px solid #000000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 0 1 auto;
}

.member-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.member h3 {
  padding-left: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.member p {
  padding-left: 12px;
  font-size: 14px;
  color: #777;
  margin-bottom: 0;
}

.linkedin-icon {
  padding: 2px 0px 5px 12px;
}

/* Work Together Section */
.work-together-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #000000;
  margin-bottom: 120px;
}

.text-content {
  flex: 1;
  max-width: 50%;
  margin: 0 auto;
}

.text-content h1 {
  font-weight: bold;
  margin-bottom: 10px;
  color: #04454a;
}

.text-content p {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 20px;
  color: #555;
}

.sign-up-btn {
  padding: 8px 20px;
  margin-top: 10px;
  background-color: #056d73;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.sign-up-btn:hover {
  background-color: rgb(20, 130, 117);
}

.image-container {
  flex: 0.45;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 100%;
  height: auto;
}

.advisors-title-mobile {
  display: None;
}

.ux-title-mobile {
  display: None;
}

.image-container-mobile {
  display: None;
}

@media (max-width: 768px) {
  .team-container {
    grid-template-columns: repeat(2, 1fr);
    /* Two columns for mobile */
  }

  .title {
    grid-column: span 2;
    /* Titles span two columns */
    text-align: center;
    /* Center-align titles */
    padding: 10px 0;
    /* Add padding to titles */
  }

  .advisors-title,
  .ux-title {
    display: none;
    /* Hide desktop titles */
  }

  .advisors-title-mobile,
  .ux-title-mobile {
    display: block;
    /* Show mobile titles */
    grid-column: span 2;
    /* Span two columns */
    text-align: center;
    /* Center-align mobile titles */
    padding: 10px 0;
    /* Add padding to mobile titles */
  }

  .column {
    grid-column: span 2;
    /* Columns span two columns */
  }

  .marketing,
  .ux-engineering,
  .advisors {
    grid-column: span 2;
    /* Sections span two columns */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two images per row */
    gap: 20px;
    margin: 10 px;
  }

  .member {
    width: 100%;
    /* Full width for mobile */
    max-width: none;
    /* Remove max-width restriction */
    /* Add padding to images */
  }

  .member-image {
    width: 100%;
    /* Full width for mobile */
    max-width: none;
    /* Remove max-width restriction */
    /* Add padding to images */
  }

  .linkedin-icon {
    padding: 2px 0px 5px 12px;
    /* Adjust padding for LinkedIn icon */

  }

  .work-together-section {
    flex-direction: column;
    /* Stack text and image vertically */
  }

  .text-content {
    max-width: 100%;
    /* Full width for mobile */
    text-align: center;
    /* Center-align text */
  }

  .image-container {
    flex: 1;
    /* Full width for mobile */
  }

  .about-us-team-member-photo {
    width: 100%;
  }

  .linkedin-icon-up {
    float: left;
  }

  .about-us-team-member-details {
    text-align: justify;
    margin: 20px;
  }

  .about-us-mission-vision {
    text-align: justify;
    clear: both;
  }

  .about-us-section {
    text-align: center;
  }

  .work-together-section {
    text-align: right;
    border: none;

  }

  .team-container {
    margin: 20 px;
  }

  .sign-up-btn {
    float: left;
    margin-left: 15px;
    margin-bottom: 15px;
  }

  .image-container {
    display: None;
  }

  .image-container-mobile {
    flex: 1;
    /* Allow the container to grow and fill available space */
    display: flex;
    justify-content: center;
    /* Center the image horizontally */
    align-items: center;
    /* Center the image vertically */
    max-width: 100%;
    /* Ensure it doesn't exceed the width of its parent */
    overflow: hidden;
    /* Hide any overflow */
  }

  .image-container-mobile img {
    width: 100%;
    /* Make the image take the full width of the container */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    /* Ensure the image covers the container without distortion */
  }

  .about-us-mission-container {
    flex-direction: column;
  }

}


/* PRODUCTS PAGE */
.info-header h1 {
  text-align: left;
  padding-top: 40px;
  margin: 0px 20% 10px 20%;
  color: #04454A;
}

.info-header p {
  text-align: left;
  margin: 0 20% 50px;
  font-weight: 500;
  color: rgb(85, 85, 85);
}

.info-services-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  align-items: stretch;
  /* Makes all cards equal height */
}


.info-service-card {
  width: 300px;
  /* background: #f9f9f9; */
  /* padding: 20px; */
  text-align: center;
  display: flex;
  background-color: white;
  flex-direction: column;
  /* Ensures the button stays at the bottom */
  height: 100%;
  /* Ensures uniform height for all cards */
}

.info-service-img {
  width: 100%;
  /* border-radius: 8px; */
}

.info-service-title {
  font-size: 28px;
  font-weight: bold;
  margin: 10px 0;
  color: #04454A;
}

.info-service-subtitle {
  font-size: 16px;
  margin-bottom: 10px;
}

.info-service-description {
  list-style: none;
  text-align: left;
  font-size: 14px;
  padding: 10px;
  white-space: pre-line;
  flex-grow: 1;
  /* Allows the description to expand naturally */
}

.info-service-description strong {
  color: rgb(126, 126, 126);
}

.info-service-description li {
  margin-bottom: 8px;
}

/* Align button at the bottom of the card */
.info-service-button {
  background: #02727a;
  color: white;
  padding: 10px;
  border: none;
  width: 80%;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: auto;
  /* Pushes button to the bottom */
}

.info-service-button:hover {
  background: darkcyan;
}


.cta-section {
  margin-left: 10%;
  padding: 4rem 6rem;
}

.cta-section h1 {
  color: #04454A;
  font-weight: bold;
}

.cta-button {
  background-color: #FF6F61;
  color: #ffffff;
  padding: 0.5rem 2rem;
  border-radius: 0.375rem;
  font-size: 1.25rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 18px;
}

.cta-button:hover {
  background-color: #FF5F52;
}

.why-choose-us {
  margin: 20px 15% 10px 15%;
}

.why-choose-us .section-title {
  text-align: left;
  padding-top: 40px;
  color: #04454A;
  font-weight: bold;
  margin-bottom: 24px;
}

.why-choose-us .features-container {
  display: flex;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
}

.why-choose-us .feature-card {
  /* width: 180px; */
  text-align: center;
}

.why-choose-us .icon-container {
  display: flex;
  justify-content: center;
  color: #1E40AF;
  width: 150px;
}

.why-choose-us .feature-title {
  font-weight: 600;
  color: #222;
  margin-top: 10px;
  font-size: 22px;
}

/* Feature Description */
.why-choose-us .feature-description {
  font-size: 14px;
  color: #000000;
  margin-top: 5px;
}