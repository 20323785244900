/* Dashboard Container  */
.grid-container {
  display: grid;
  grid-template-columns: 270px 3fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'sidebar header header header'
    'sidebar main main main';
  height: 100vh;
  position: relative;
}



/* Header  */
.dash-header {
  grid-area: header;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background-color: #ffffff;
  z-index: 15;
}

.dash-header .dash-icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}

.menu-icon {
  display: none;
}

.dash-header .profile-icon {
  width: 65px;
  padding: 12px;
}

.dash-header .header-right p {
  font-weight: 600;
  padding-top: 20px;
  font-size: medium;
  float: left;
}

.dash-icon {
  margin-right: 5px;
}

.close-icon {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.profile-container {
  position: relative;
  display: flex;
  align-items: center;
}

.dropdown-icon {
  cursor: pointer;
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  border-radius: 10px;
  top: 65px;
  right: 0;
  padding: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: block;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-menu li label {
  cursor: pointer;
  font-weight: 400;
  color: black;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}





/* Sidebar  */
.sidebar {
  grid-area: sidebar;
  height: 100%;
  background-color: #ffffff;
  overflow-y: auto;
  transition: all 0.5s;
  padding-left: 10px;
  position: relative;
  z-index: 10;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0px 0px 25px;
  margin-bottom: 30px;
}

.sidebar-title>span {
  display: none;
}

.sidebar-brand {
  display: flex;
  /* margin-top: 15px;
   font-size: 20px;
   font-weight: 700; */
}

.sidebar-brand img {
  width: 35px;
  cursor: pointer;
}

.logout-icon {
  position: absolute;
  /* This ensures that the icon stays fixed on the screen */
  bottom: 0;
  /* Places the icon at the bottom */
  /* transform: translateX(-50%);  */
}

.sidebar-brand h4 {
  /* padding-top: 10px; */
  padding-left: 12px;
  /* font-weight: bold; */
  /* color: #05865b; */
}

.sidebar-list {
  padding: 0;
  text-align: left;
  list-style-type: none;
}

.sidebar-list-item {
  color: #8b8b8b;
  padding: 18px;
  font-size: 18px;
  cursor: pointer;
}

.sidebar-list-item img {
  margin-bottom: 5px;
}

.sidebar-list-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.sidebar-list-item::after {
  color: #05865b;
}

.sidebar-list-item label {
  color: #8b8b8b;
  margin-left: 20px;
  cursor: pointer;
  font-weight: 400;
  /* text-decoration: none; */
}

.sidebar-responsive {
  display: block !important;
  position: fixed;
  /* Fixed positioning to overlap everything */
  left: 0;
  top: 0;
  /* Start from the very top */
  width: 260px;
  height: 100vh;
  /* Full viewport height */
  z-index: 20 !important;
  /* Higher than the header to overlap */
  transition: left 0.3s ease;
  overflow-y: auto;
  /* Smooth slide in effect */
}

.app-container.sidebar-open {
  overflow: auto;
}

.sidebar-open .sidebar {
  transform: translateX(0);
  /* Bring sidebar into view */
}

.sidebar-list-item.active {
  background-color: #f0f3fa;
  color: rgb(46, 98, 255);
  border-left: 5px solid rgb(46, 98, 255);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.sidebar-list-item.active a {
  color: rgb(46, 98, 255);
}

@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    /* Single column layout */
    grid-template-rows: 80px 1fr;
    /* Header on top */
    grid-template-areas:
      'header'
      'main';
  }

  .sidebar {
    position: fixed;
    left: -260px;
    /* Initially hidden */
    top: 0;
    /* Position below the header */
    height: 100vh;
    /* Full height minus header */
    transition: left 0.3s ease;
    /* Smooth transition when opening */
  }

  .sidebar.sidebar-responsive {
    left: 0;
    /* Slide in from the left */
  }

  .app-container.sidebar-open .sidebar {
    left: 0;
    /* Show the sidebar when open */
  }

  .sidebar-open .sidebar {
    transform: translateX(0);
    /* Show sidebar when opened */
  }

  .menu-icon {
    display: block;
  }

  .close-icon {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .sidebar-title>span {
    display: inline;
  }

  .hedaer-left {
    display: none;
  }

  .logout-icon {
    bottom: 50px;
  }
}


/* Default Patient Container */
.patients-container {
  padding: 15px;
  background-color: rgb(241, 242, 243);
}

.form-container {
  text-align: left;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  /* margin: 10px; */
}

.form-header {
  text-align: left;
  /* display: flex; */
  /* justify-content: space-between; */
  margin-bottom: 20px;
}

.upload-button {
  background-color: rgb(23, 19, 242);
  font-size: 14px;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.cancel-button {
  background: none;
  color: #9c9c9c;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.add-patient-form {
  display: grid;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  gap: 20px;
}

.in-row-input {
  display: flex;
  justify-content: space-between;
  /* Ensures space between the options and button */
  align-items: flex-start;
  /* Aligns the button with the top of the container */
  gap: 20px;
}


.form-group {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-weight: 500;
}

input,
select,
textarea {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.kit-selection-buttons {
  text-align: left;
}

.kit-selection-buttons button {
  background-color: #ffffff;
  /* padding: 10px 20px; */
  border: none;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 20px;
}

.testing-options-container {
  flex: 1;
  padding: 20px;
  /* background-color: #ffffff; */
  border-radius: 10px;
  /* max-width: 100%; */
  /* margin: auto; */
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
}

.testing-group {
  margin-bottom: 20px;
}

.testing-option {
  /* align-items: center; */
  /* margin-bottom: 10px;  */
  display: flex;
  flex-wrap: wrap;
  /* Allows wrapping of items into multiple lines */
  gap: 10px;
}

.sub-options {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-left: 20px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.testing-suboption {
  display: flex;
  flex: 1 1 30%;
  min-width: 180px;
  box-sizing: border-box;
}

.testing-suboption input {
  margin-right: 10px;
}

.customized-kit-button,
.standard-kit-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  /* Adjust size as needed */
  padding: 10px 20px;
  /* Adjust padding as needed */
  position: relative;
  /* For positioning the underline */
}

.active-button {
  color: blue;
  /* Text color when active */
}

.active-button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  /* Adjust spacing as needed */
  width: 100%;
  height: 2.5px;
  /* Adjust thickness as needed */
  background-color: blue;
}

.save-button {
  background-color: rgb(29, 26, 222);
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  padding: 5px 10px;
  /* height: 40px; */
  /* margin-top: 0; */
  /* align-self: flex-end; */
}

.profile-save-button {
  background-color: rgb(23, 19, 242);
  color: white;
  border: none;
  margin-top: 20px;
  border-radius: 15px;
  cursor: pointer;
  width: 150px;
  height: 40px;
}

.profile-radio-button input {
  margin-right: 10px;
}

.profile-radio-button label {
  margin-right: 25px;
}

@media (max-width: 768px) {
  .in-row-input {
    flex-direction: column;
    gap: 10px;
  }

  .testing-options-container {
    padding: 15px;
    border-radius: 8px;
  }

  .testing-suboption {
    flex: 1 1 45%;
    min-width: 150px;
  }

  .save-button {
    width: 80px;
    height: 35px;
  }

  .form-container {
    padding: 15px;
  }

  .upload-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .cancel-button {
    font-size: 14px;
  }

  .form-group {
    width: 100%;
  }

  .testing-suboption {
    flex: 1 1 100%;
    min-width: 100%;
  }

  .sub-options {
    padding-left: 10px;
  }

  .save-button {
    width: 100%;
    margin-top: 10px;
  }
}


/* Lab Form  */
.lab-form .form-group{
  margin-bottom: 15px
}


/* Add Patients Container  */
.add-patient-container {
  text-align: left;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  overflow: visible; /* Allow overflow */
}

.add-patient-container h2 {
  margin-bottom: 15px;
}

.table-container {
  max-height: 460px;
  overflow-y: auto; /* Keep only vertical scrolling */
  overflow-x: visible; /* Allow overflow horizontally */
  border-radius: 10px;
  margin-bottom: 20px;
  scroll-behavior: smooth;
  position: relative; /* Important to maintain context for absolute positioning */
}

.table-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.table-container::-webkit-scrollbar-thumb {
  border-radius: .5rem;
  background-color: #0004;
}

.add-patient-container table {
  width: 100%;
  border-collapse: collapse;
}

.add-patient-container thead {
  background-color: #f0f0f0;
}

.add-patient-container th,
td {
  padding: 6px 0px 6px 6px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.add-patient-container .table-container input,
select {
  padding: 6px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-patient-container .add-button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 20px;
}

.add-patient-container .remove-button {
  padding: 6px;
  color: rgb(255, 0, 0);
  border: solid 1px #ccc;
  border-radius: 8px;
  cursor: pointer;
}

.add-patient-container .remove-button {
  background-color: #ffffff;
}

.add-patient-container button:hover {
  opacity: 0.8;
}

.address-container {
  position: relative;
}

.address-input {
  width: 100%;
  box-sizing: border-box;
}

.address-fields {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000; /* Ensure it overlaps other elements */
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: visible;
}


.address-fields input {
  width: 100%;
  margin-bottom: 8px;
}


/* Book Appointments Container  */
.booking-container {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.left-section {
  flex: 7;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  /* background-color: #f9f9f9; */
}

.right-section {
  flex: 3;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
}

.appointments-container {
  max-height: 560px;
  overflow-y: auto;  
  overflow-x: visible;  
  scroll-behavior: smooth;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.appointments-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.appointments-container::-webkit-scrollbar-thumb {
  border-radius: .5rem;
  background-color: #0004;
}

.appointment-card {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}
.appointment-card p{
  margin: 4px;
}

.appointment-card a{
  text-decoration: none;
}

.appointment-card i {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: #555;
  cursor: pointer;
}

.appointment-card i:hover {
  color: #000; /* Darker color on hover */
}




/* Existing labs Container  */
.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  padding: 8px;
  border: none;
  background-color: rgb(245, 247, 250);
  border-radius: 20px;
  outline: none;
  width: 400px;
  margin-right: 10px;
}

.filter-button {
  background: none;
  border: none;
  color: #555;
  font-weight: bold;
  cursor: pointer;
}

.update-button {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 5px 8px;
  cursor: pointer;
}

.update-button:hover {
  background-color: #f0f0f0;
}

.not-authorized {
  padding: 8px;
}


/* Add lab container  */
.lab-form-container {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 20px;
  flex: 1;
}

.in-row-form {
  display: flex;
  justify-content: space-between;
}

.in-row-form .lab-form-container {
  width: 48%;
}


/* Kits Info  */
.kit-section {
  margin-bottom: 30px;
}

.kit-section h2 {
  font-size: 24px;
  color: #333;
}

.kit-section h3 {
  font-size: 20px;
  color: #555;
}

.kit-section ul {
  list-style-type: disc;
  margin-left: 20px;
}

.kit-section li {
  margin: 10px 0;
}

.dashboard-container {
  padding: 20px;
  text-align: center;
}

.kit-buttons button {
  margin: 20px;
  padding: 5px 20px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  background-color: #f4f7ff;
  color: rgb(0, 0, 0);
  border-radius: 8px;
}

.kit-buttons button:hover {
  background-color: #b5d1ee;
}

.kit-info {
  margin-top: 20px;
  text-align: left;
}

.kits-container {
  max-height: 470px;
  overflow-y: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  scroll-behavior: smooth;
}

.kits-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.kits-container::-webkit-scrollbar-thumb {
  border-radius: .5rem;
  background-color: #0004;
}